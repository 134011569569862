"use strict";
/*jslint node: true */
/*global module, document, localStorage, require */


const rsv4Url = `https://rsv4.mastercamping.com`;

const Service = function (options) {
    this.options = options || {};
    this.options.cacheSecs = this.options.cacheSecs || (60 * 5);
};

Service.prototype.getCacheKey = function (lang = null, promotionCode = null, suffix = '') {
    let cacheKey = suffix;
    if (this.options.propertyId != null) {
        cacheKey = cacheKey + `_${this.options.propertyId}`
    }
    if (this.options.propertyGroupId != null) {
        cacheKey = cacheKey + `_${this.options.propertyGroupId}_${this.options.propertyId}`
    }
    if (lang != null) {
        cacheKey = cacheKey + `_${lang}`;
    }
    if (promotionCode != null) {
        cacheKey = cacheKey + `_${promotionCode}`;
    }
    return cacheKey;
};

Service.prototype.saveCache = function (cacheKey, data) {
    const obj = {
        expirationDate: Date.now() + this.options.cacheSecs * 1000,
        data
    };
    localStorage[cacheKey] = JSON.stringify(obj);
};

Service.prototype.loadCache = function (cacheKey) {
    const S = localStorage[cacheKey];
    if (!S) return null;
    try {
        const obj = JSON.parse(S);
        if (Date.now() > obj.expirationDate) return null;
        return obj.data;
    } catch (err) {
        return null;
    }
};

Service.prototype.getHeaders = function () {
    const headers = new Headers();
    if (this.options.propertyGroupId && this.options.propertyId) {
        headers.set('property-group-id', this.options.propertyGroupId);
        headers.set('property-ids', this.options.propertyId);
    }
    return headers;
};

Service.prototype.request = function (url, options = {}) {
    return fetch(url, options).then(function (response) {
        if (!response.ok) {
            console.error(response.statusText);
            throw Error(response.statusText);
        }
        return response.json();
    })
};

Service.prototype.getInfo = function (lang) {
    const self = this;

    let cacheKey = this.getCacheKey(`McsInfo_`);

    const info = this.loadCache(cacheKey);

    if (info != null) {
        // return new Promise(resolve => resolve(info));
    }

    if (self.InfoPromise) {
        return self.InfoPromise;
    }

    const headers = this.getHeaders();

    let url = `${rsv4Url}/api/info?`;
    if (self.options.idProperty) {
        url += 'idProperty=' + self.options.idProperty;
    }
    if (lang != null) {
        url += '&lang=' + lang;
    }
    if (self.options.promotionCode != null) {
        url += '&promotionCode=' + self.options.promotionCode;
    }

    self.InfoPromise = this.request(url, {headers});
    self.InfoPromise.then((data) => {
        self.saveCache(cacheKey, data);
    });
    return self.InfoPromise;
};

Service.prototype.getAvailability = function () {
    const self = this;

    let cacheKey = this.getCacheKey(`McsAvailability_`);
    const availability = this.loadCache(cacheKey);
    if (availability != null) {
        // return new Promise(resolve => resolve(availability));
    }

    if (self.availabilityPromise != null) {
        return self.availabilityPromise;
    }

    const headers = this.getHeaders();

    let url = `${rsv4Url}/api/availability?agregate=PRODUCT`;

    if (self.options.idProperty) {
        url += '&idProperty=' + self.options.idProperty;
    }

    if (self.options.promotionCode != null) {
        url += '&promotionCode=' + self.options.promotionCode;
    }

    self.availabilityPromise = this.request(url, {headers});
    self.availabilityPromise.then((data) => {
        Object.keys(data).forEach((productUuid) => {
            if (data[productUuid].matrix) {
                data[productUuid].matrix = data[productUuid].matrix.map(outAvailability => {
                    if (Array.isArray(outAvailability)) {
                        outAvailability = 0;
                    }
                    return outAvailability;
                });
            }
        });
        self.saveCache(cacheKey, data);
    });
    return self.availabilityPromise;
};

Service.prototype.getBudgets = function (params) {
    const self = this;
    if (self.budgetsPromise) {
        return self.InfoPromise;
    }
    const headers = this.getHeaders();
    let url = `${self.options.url}/api/budgets?`;
    if (self.options.idProperty) {
        url += 'idProperty=' + self.options.idProperty;
    }
    if (params != null) {
        url = url + '&' + params.toString();
    }
    self.budgetsPromise = this.request(url, {headers});
    return self.budgetsPromise;
};

module.exports = Service;

