"use strict";
/*jslint node: true */
/*global window, module, document, navigator */

const coman = require('./coman.js');
const moment = require('moment');

const CategoriesSelector = coman.createComponent({
    init: function (options) {
        const self = this;
        self.options = Object.assign({
            enabled: true,
            visible: true
        }, self.options);
        self.visible = self.options.visible;
        self.enabled = self.options.enabled;
        self.popupVisible = false;
        self.selectedCategories = [];
        self.expandedCategoryGroups = [];
    },
    render: function () {
        const self = this;
        moment.locale(self.lang);

        if (self.visible === false) {
            return '';
        }

        let html = "<div ";

        html += " id=\"" + self.uid + "\"";

        // class
        html += " class=\"categorySelector ";
        html += self.options.class.join(" ");
        html += "\" ";

        html += ">";

        if (self.options.dropdown) {
            const classes = [];
            classes.push("inputBox");

            if (!self.selDate) {
                classes.push("empty");
            }

            if (self.enabled) {
                classes.push("enabled");
            } else {
                classes.push("dissabled");
            }

            html += '<span id="' + self.uid + '_input' + '" ';
            html += 'class="' + classes.join(" ") + '" ';
            if (self.enabled) {
                html += self.addEvent("onClick", function () {
                    if (self.popupVisible) {
                        self.hidePopup();
                    } else {
                        self.showPopup();
                    }
                });
            }

            html += ">";
            html += self.sumSelected() + '&nbsp;';
            html += "</span>";
        } else {
            html += self.renderCategoryTree();
        }

        if (self.popupVisible && self.enabled) {
            html += self.renderPopup();
        }
        html += "</div>";

        return html;
    },
    renderCategoryTree: function () {
        const self = this;
        let html = '';

        if (!self.info) return html;

        const categoryGroups = [];
        const orphanCategories = [];

        self.info.categories = self.info.categories.sort(function (c) {
            return c.order != null ? parseInt(c.order) : 0;
        });

        self.info.categories.forEach(function (c) {
            const cat = {
                idCategory: c.idCategory,
                name: c.name
            };
            let cg = categoryGroups.find((category) => category.idCategoryGroup === c.idCategoryGroup);;
            if (cg == null && self.info.categoryGroups != null) {
                const icg = self.info.categoryGroups.find(category => category.idCategoryGroup === c.idCategoryGroup);
                if (icg != null) {
                    cg = {
                        idCategoryGroup: icg.idCategoryGroup,
                        name: icg.name,
                        categories: []
                    };
                    categoryGroups.push(cg);
                }
            }
            if (cg) {
                cg.categories.push(cat);
            } else {
                orphanCategories.push(cat);
            }
        });

        categoryGroups.forEach(function (cg) {
            html += self.renderCategoryGroup(cg);
        });
        orphanCategories.forEach(function (c) {
            html += self.renderCategory(c);
        });

        return html;
    },
    renderPopup: function () {

        function getPosition() {
            let style = "";

            let el = document.getElementById(self.uid + '_input');
            const elw = el.clientWidth;
            const elh = el.clientHeight;
            let ex, ey;

            /*
                        for (ex=0, ey=0;
                             el !== null;
                             ex += el.offsetLeft, ey += el.offsetTop, el = el.offsetParent);

            */


            const boundingRect = el.getBoundingClientRect();
            ex = boundingRect.left;
            ey = boundingRect.top;


            /*
            ex =  el.offsetLeft;
            ey = el.offsetTop;
            */

            const w = window.innerWidth;
            const h = window.innerHeight;
            const puw = 400;
            const puh = 300;

            if (ex + puw < w) {
                style += "left: " + ex + 'px; ';
            } else {
                style += "right: 0px; ";
            }

            if (ey + elh + puh > h) {
                style += "bottom: " + (h - ey + 2) + 'px; ';
            } else {
                style += "top: " + (ey + elh + 2) + 'px; ';
            }

            return 'style="' + style + '" ';
        }

        const self = this;

        let html = '';

        html += '<div class="fullScreenPopup" ';
        html += self.addEvent("onClick", function () {
            self.hidePopup();
        });
        html += '>';

        html += '<div class="categoriesPopup" ';
        html += getPosition();
        html += self.addEvent("onClick", function (event) {
            event.stopPropagation();
        });
        html += '>';

        if (self.options.title) {
            html += '<div class="popupTitle">';
            html += self.encodeStr(self.options.title);
            html += '</div>';
        }

        let classes = [];
        classes.push("closeButton");
        html += '<div ';
        html += 'class="' + classes.join(' ') + '" ';
        html += self.addEvent('onclick', function () {
            self.hidePopup();
        });
        html += ">";
        html += "<span></span>";
        html += "</div>";

        html += self.renderCategoryTree();

        html += '</div>';   // categoriesPopup

        html += '</div>';   // fullscreenPopup
        return html;
    },

    renderCategoryGroup: function (cg) {
        const self = this;
        let classes;
        let html = '';
        let val;

        function groupClick(ev, idCategoryGroup) {
            idCategoryGroup = idCategoryGroup.toString();
            let select;
            if (self.allSelected(idCategoryGroup)) {
                select = false;
            } else {
                select = true;
            }
            self.info.categories.forEach(function (c) {
                if (c.idCategoryGroup === idCategoryGroup) {
                    if (select) {
                        self.selectCategory(c.idCategory);
                    } else {
                        self.unselectCategory(c.idCategory);
                    }
                }
            });
        }

        classes = ['categoryGroupContainer'];
        html += '<div ';
        html += 'id="' + self.uid + '_categoryGroup_' + cg.idCategoryGroup + '" ';
        html += 'class="' + classes.join(' ') + '" ';
        html += '>';

        classes = ['categoryGroupCB'];
        if (self.allSelected(cg.idCategoryGroup)) {
            classes.push('selected');
            val = "All";
        } else if (self.noneSelected(cg.idCategoryGroup)) {
            classes.push('unselected');
            val = "none";
        } else {
            classes.push('tstate');
            val = "some";
        }
        html += '<div ';
        html += 'id="' + self.uid + '_categoryGroupCB_' + cg.idCategoryGroup + '" ';
        html += 'class="' + classes.join(' ') + '" ';
        html += self.addEvent('onclick', groupClick, cg.idCategoryGroup.toString());
        html += '>';

        html += '<span>' + val + '</span>';

        html += '</div>'; // categoryGroupCB

        let expanded = self.categoryGroupExpanded(cg.idCategoryGroup);

        if (expanded === false) {
            const categories = cg.categories.map(function (c) {
                return c.idCategory;
            });
            // const crossCategories = intersection(self.selectedCategories, categories);
            const crossCategories = self.selectedCategories.filter((categoryId) => categories.includes(categoryId));
            if (categories.length !== crossCategories.length && crossCategories.length > 0) {
                expanded = true;
            }
        }

        if (expanded) {
            classes = ['categoryGroupExpand'];
            html += '<button type="button" ';
            html += 'id="' + self.uid + '_expand_' + cg.idCategoryGroup + '" ';
            html += 'class="' + classes.join(' ') + '" ';
            html += self.addEvent('onclick', function (ev, idCategoryGroup) {
                idCategoryGroup = idCategoryGroup.toString();
                self.collapseCategoryGroup(idCategoryGroup);
            }, cg.idCategoryGroup.toString());
            html += '>';
            html += '<span>-</span>';

            html += '</button>'; // categoryGroupExpand
        } else {
            classes = ['categoryGroupCollapse'];
            html += '<button type="button" ';
            html += 'id="' + self.uid + '_collapse_' + cg.idCategoryGroup + '" ';
            html += 'class="' + classes.join(' ') + '" ';
            html += self.addEvent('onclick', function (ev, idCategoryGroup) {
                idCategoryGroup = idCategoryGroup.toString();
                self.expandCategoryGroup(idCategoryGroup);
            }, cg.idCategoryGroup.toString());
            html += '>';
            html += '<span>+</span>';
            html += '</button>'; // categoryGroupCollapse
        }

        classes = ['categoryGroupLabel'];
        html += '<label ';
        html += 'id="' + self.uid + '_categoryGroupLabel_' + cg.idCategoryGroup + '" ';
        html += 'class="' + classes.join(' ') + '" ';
        html += self.addEvent('onclick', groupClick, cg.idCategoryGroup.toString());
        html += '>';

        html += cg.name;

        html += '</label>'; // label

        if (expanded) {
            classes = ['categoryGroupChilds'];
            html += '<div ';
            html += 'id="' + self.uid + '_categoryGroupChilds_' + cg.idCategoryGroup + '" ';
            html += 'class="' + classes.join(' ') + '" ';
            html += '>';

            cg.categories.forEach(function (c) {
                html += self.renderCategory(c);
            });

            html += '</div>'; // categoryGroupChilds
        }

        html += '</div>'; // categoryGroupContainer
        return html;
    },
    renderCategory: function (c) {
        const self = this;
        let classes;
        let html = '';
        let val;

        function categoryClick(ev, idCategory) {
            idCategory = idCategory.toString();
            if (self.categorySelected(idCategory)) {
                self.unselectCategory(idCategory);
            } else {
                self.selectCategory(idCategory);
            }
        }

        classes = ['categoryContainer'];
        html += '<div ';
        html += 'id="' + self.uid + '_category_' + c.idCategory + '" ';
        html += 'class="' + classes.join(' ') + '" ';
        html += '>';

        classes = ['categoryGroupCB'];
        if (self.categorySelected(c.idCategory)) {
            classes.push('selected');
            val = 'selected';
        } else {
            classes.push('unselected');
            val = 'unselected';
        }
        html += '<div ';
        html += 'id="' + self.uid + '_categoryCB_' + c.idCategory + '" ';
        html += 'class="' + classes.join(' ') + '" ';
        html += self.addEvent('onclick', categoryClick, c.idCategory.toString());
        html += '>';
        html += '<span>' + val + '</span>';

        html += '</div>'; // categoryGroupCB

        classes = ['categoryLabel'];
        html += '<label ';
        html += 'id="' + self.uid + '_categoryLabel_' + c.idCategory + '" ';
        html += 'class="' + classes.join(' ') + '" ';
        html += self.addEvent('onclick', categoryClick, c.idCategory.toString());
        html += '>';

        html += c.name;

        html += '</label>'; // label

        html += '</div>'; // categoryContainer
        return html;
    },
    categoryGroupExpanded: function (idCategoryGroup) {
        const self = this;
        return self.expandedCategoryGroups.includes(idCategoryGroup);
    },
    allSelected: function (idCategoryGroup) {
        const self = this;
        const catInGroupNotSelected = self.info.categories.find( function (c) {
            if (c.idCategoryGroup === idCategoryGroup) {
                if (!self.categorySelected(c.idCategory)) {
                    return true;
                }
            }
            return false;
        });
        if (catInGroupNotSelected) {
            return false;
        } else {
            return true;
        }
    },
    noneSelected: function (idCategoryGroup) {
        const self = this;
        const catInGroupSelected = self.info.categories.find(function (c) {
            if (c.idCategoryGroup === idCategoryGroup) {
                if (self.categorySelected(c.idCategory)) {
                    return true;
                }
            }
            return false;
        });
        if (catInGroupSelected) {
            return false;
        } else {
            return true;
        }
    },
    expandCategoryGroup: function (idCategoryGroup) {
        const self = this;
        if (!self.expandedCategoryGroups.includes(idCategoryGroup)) {
            self.expandedCategoryGroups.push(idCategoryGroup);
            self.invalidate();
        }
    },
    collapseCategoryGroup: function (idCategoryGroup) {
        const self = this;
        const idx = self.expandedCategoryGroups.indexOf(idCategoryGroup);
        if (idx >= 0) {
            self.expandedCategoryGroups[idx] = self.expandedCategoryGroups[self.expandedCategoryGroups.length - 1];
            self.expandedCategoryGroups.pop();
            self.invalidate();
        }
    },
    selectCategory: function (idCategory) {
        const self = this;
        if (!self.selectedCategories.includes(idCategory)) {
            self.selectedCategories.push(idCategory);
            self.invalidate();
            if (self.onChange) self.onChange(self.selectedCategories);
        }
    },
    unselectCategory: function (idCategory) {
        const self = this;
        const idx = self.selectedCategories.indexOf(idCategory);
        if (idx >= 0) {
            self.selectedCategories[idx] = self.selectedCategories[self.selectedCategories.length - 1];
            self.selectedCategories.pop();
            self.invalidate();
            if (self.onChange) self.onChange(self.selectedCategories);
        }
    },
    categorySelected: function (idCategory) {
        const self = this;
        return self.selectedCategories.includes(idCategory);
    },
    setEnabled: function (enabled) {
        const self = this;
        if (self.enabled !== enabled) {
            self.enabled = enabled;
            self.invalidate();
        }
    },
    setVisible: function (visible) {
        const self = this;
        if (self.visible !== visible) {
            self.visible = visible;
            self.invalidate();
        }
    },
    setInfo: function (info) {
        const self = this;
        self.info = info;
        self.invalidate();
    },
    showPopup: function () {
        const self = this;

        self.popupVisible = true;
        self.invalidate();
    },
    hidePopup: function () {
        const self = this;
        self.popupVisible = false;
        self.invalidate();
    },
    sumSelected: function () {
        const self = this;
        const texts = [];

        if (!self.info) {
            return self.gettextCatalog.getString("loading...");
        }

        function allCatsSelected() {
            // I find a category not selected, then not all are selected.
            // otherwise if I don't find any category not selected them all are selected.

            const notSelectedCat = self.info.categories.find(function (c) {
                if (!self.categorySelected(c.idCategory)) {
                    return true;
                } else {
                    return false;
                }
            });
            if (notSelectedCat) {
                return false;
            } else {
                return true;
            }
        }

        const pendingCats = {};
        self.selectedCategories.forEach(function (idCategory) {
            pendingCats[idCategory] = self.info.categories.find(category => category.idCategory === idCategory);
        });

        if (allCatsSelected()) {
            return self.gettextCatalog.getString("Any accommodation");
        }

        if (self.info.categoryGroups != null) {
            self.info.categoryGroups.forEach(function (cg) {
                if (self.allSelected(cg.idCategoryGroup)) {
                    texts.push(cg.name);
                    Object.values(pendingCats).forEach(function (c) {
                        if (c.idCategoryGroup === cg.idCategoryGroup) {
                            delete pendingCats[c.idCategory];
                        }
                    });
                }
            });
        }

        Object.values(pendingCats).forEach(function (c) {
            texts.push(c.name);
        });
        return texts.join(', ');
    },
    setSelectedCategories: function (selectedCategories) {
        const self = this;
        self.selectedCategories = selectedCategories.map(function (c) {
            return c.toString();
        });
        self.invalidate();
    },
    setCategoryGroups: function(expandedCategoryGroups) {
        if (Array.isArray(expandedCategoryGroups)) {
            const self = this;
            self.expandedCategoryGroups = expandedCategoryGroups.map(function (c) {
                return c.toString();
            });
            self.invalidate();
        }
    }
});


module.exports = CategoriesSelector;
